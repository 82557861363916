import React from 'react'
import Homepage from '../components/DynamicZone/homepage/Homepage'

export default function ErrorPage() {

	const inputData = {
		homepageTitle: "404 PAGE NOT FOUND",
		homepageDescription: "Oops! The page that you're looking for has been removed or relocated.",
		homepageLinkUrl: "/"
	}

	return (
		<Homepage input={ inputData } />
	)
}