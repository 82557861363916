import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Arrow from '../../../images/svgs/arrow.svg'
import Plant from '../../../images/svgs/plant.svg'
import ComponentAnimation from '../../ComponentAnimation/ComponentAnimation'

import { addTrailingSlash } from '../../../helpers/urlHelpers'

export default function Homepage( props ) {

	const { homepageTitle, homepageDescription, homepageLinkUrl } = props.input

	return (
		<div className="homepage">
			<h1 className="homepage__title">{ homepageTitle }</h1>
			{ homepageDescription && <h2 className="homepage__sub-title">{ homepageDescription }</h2> }

			<Link to={ addTrailingSlash( homepageLinkUrl ) } className="homepage__link">
				<Arrow className="homepage__arrow"/>
			</Link>
			<ComponentAnimation horizontal={ true } reverse={ true }>
				<div className="homepage__plant-container homepage__plant-container--1">
					<Plant className="homepage__plant homepage__plant--1"/>
				</div>
			</ComponentAnimation>
			<ComponentAnimation horizontal={ true } reverse={ true }>
				<div className="homepage__plant-container homepage__plant-container--2">
					<Plant className="homepage__plant homepage__plant--2"/>
				</div>
			</ComponentAnimation>
			<ComponentAnimation horizontal={ true }>
				<div className="homepage__plant-container homepage__plant-container--3">
					<Plant className="homepage__plant homepage__plant--3"/>
				</div>
			</ComponentAnimation>
		</div>
	)
}

Homepage.propTypes = {
	input: PropTypes.object.isRequired
};